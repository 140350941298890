import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#E7E1D1',
      neutralDark2: '#9A967C',
      neutralDark1: '#000000',
      primaryDark: '#2C2E20',
      primaryLight: '#96423C',
    },
  },
  fontFamily: {
    heading: "'Outfit', sans-serif",
    paragraph: "'Roboto Mono', monospace",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
